import {
  Box,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  Text,
  Link,
  useColorMode,
  Tooltip,
} from "@chakra-ui/react";
import ToolTipIssue from "../ToolTip/ToolTipIssue";
import {FlagIcon, InfoIcon} from "../../assets/icons/icons";
import ToolTipTotal from "../ToolTip/ToolTipTotal";
import Rating from "../Rating/Rating";
import { getProductCharacteristic } from "../../utils/getProductCharacteristic";
import {getCurrentColor, productGroupLink} from "../../constants";
import { cautionStatus } from "../../utils/cautionStatus";
import {useAtom} from "jotai/index";
import {categoriesPageModeAtom} from "../../atom/listings";
import pluralize from "pluralize";
import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import useCookie from "../../hooks/useCookie";
import { Link as RouterLink } from "react-router-dom";
import TextTooltip from "../ToolTip/Tooltip";

const ProductItemMobile = ({ productData, recomendedBorder, slider }) => {
  const [categoriesPageMode] = useAtom(categoriesPageModeAtom);
  const isProductsCategoriesMode = categoriesPageMode === 'products';
  const { colorMode } = useColorMode();
  const [areListingsOpened, setAreListingsOpened] = useState(false);
  const { cookies } = useCookie();

  const handleOpenListingsToggle = () => {
    setAreListingsOpened(!areListingsOpened);
  }

  useEffect(() => {
    setAreListingsOpened(false)
  }, [productData]);

  const {
    price,
    cpr,
    landed_price,
    landed_cpr,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
    title,
    condition,
    vendor_name,
    vendor_rating,
    ship_flag,
    vendor_rating_count,
    affiliate_url,
    recommend_flag,
    brand_image_w,
    brand_image_b,
    gb_flag,
    ship_cost,
    taxes,
    shell_length,
    weapon_category,
    listings,
    lowest_starts_from,
    listing_id,
    product_group_id,
    listings_count,
    is_variant,
    per_unit
  } = productData;

  const characteristics = [
    weapon_category === "Shotgun" && shell_length,
    condition,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
  ];

  if(slider && !listing_id) return;

  return (
    <ListItem>
      <Box
          border={recommend_flag === "1" ? "2px solid" : "1px solid"}
          borderColor={
            recommend_flag === "1"
                ? "blueC.100"
                : getCurrentColor(colorMode, "grayC.500", "grayC.2400")
          }
          borderRadius={"5px"}
          pt={recommend_flag === "1" ? "45px" : "17px"}
          pb={3}
          px={5}
          gap={{ base: 0, md: 42 }}
          position={"relative"}
          display={{ base: "flex", md: "none" }}
          maxW={{ base: "320px", "1xs": "378px", lg: "350px" }}
          w={"100%"}
          boxShadow={
            "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
          }
          flexDirection={"column"}
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
      >
        {recommend_flag === "1" && recomendedBorder()}
        {!slider && (
            <Flex
                justifyContent={isProductsCategoriesMode ? "center" : "space-between"}
                mb={"12px"}
                alignItems={"center"}
            >
              <Image
                  src={getCurrentColor(colorMode, brand_image_w, brand_image_b)}
                  w={"90px"}
                  loading="lazy"
                  alt={title}
              />

              {!isProductsCategoriesMode &&
                  <ToolTipIssue id={listing_id} isVariant={is_variant}>
                    <Box
                        cursor={"auto"}
                        border={"1px solid"}
                        display={"flex"}
                        bottom={"10px"}
                        right={"10px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderColor={"grayC.500"}
                        borderRadius={"50%"}
                        w={"20px"}
                        h={"20px"}
                    >
                      <FlagIcon
                          w={"10px"}
                          h={"10px"}
                          color={getCurrentColor(colorMode, "grayC.2700", "grayC.1600")}
                      />
                    </Box>
                  </ToolTipIssue>
              }
            </Flex>
        )}
        <Flex flexDirection={"column"} w={"100%"}>
          <Flex w={"100%"} justifyContent={"space-between"} gap={14} mb={"12px"}>
            <List
                display={"flex"}
                w={"100%"}
                gap={3}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
            >
              {!slider && (
                  <ListItem width={"100%"}>
                    <Heading
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        fontSize={{ base: "sm", sm: "lg" }}
                        fontWeight={"600"}
                        fontFamily={"mainFont"}
                    >
                      {title}
                    </Heading>
                  </ListItem>
              )}

              {(!isProductsCategoriesMode || slider) &&
                  <ListItem>
                    <Text
                        color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                        fontSize={"xs"}
                        fontWeight={"500"}
                        fontFamily={"mainFont"}
                    >
                      Rounds
                    </Text>
                    {is_variant === 1 ?
                        <Flex gap={1}>
                        <Heading
                            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                            fontSize={'sm'}
                            fontWeight={"500"}
                            fontFamily={"mainFont"}
                            display={"flex"}
                            gap={"6px"}
                            alignItems={"center"}
                        >
                          {rdsperbox}
                        </Heading>
                      <TextTooltip text={`This listing reflects the purchase of multiple units, please make sure to add ${per_unit} ${pluralize('unit', per_unit)} to cart.`}>
                        
                        <InfoIcon pl={'3px'}/>
                      </TextTooltip>
                      </Flex> :
                        <Heading
                            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                            fontSize={"sm"}
                            fontWeight={"500"}
                            fontFamily={"mainFont"}
                        >
                          {rdsperbox}
                        </Heading>
                    }
                  </ListItem>
              }

              {
                  (!isProductsCategoriesMode || slider) &&
                  <ListItem>
                    <Text
                        color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                        fontSize={"xs"}
                        fontWeight={"500"}
                        fontFamily={"mainFont"}
                    >
                      Item Cost
                    </Text>
                    <Heading
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        fontSize={"sm"}
                        fontWeight={"500"}
                        fontFamily={"mainFont"}
                    >
                      ${(+cpr).toFixed(2)}{" "}
                      <Text as={"span"} ml={"5px"}>
                        CPR
                      </Text>
                    </Heading>
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        fontSize={"sm"}
                        fontWeight={"400"}
                        fontFamily={"mainFont"}
                    >
                      ${(+price).toFixed(2)}
                    </Text>
                  </ListItem>
              }

              {(!isProductsCategoriesMode || slider) &&
                  <ListItem>
                    <Text
                        color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                        fontSize={"xs"}
                        fontWeight={"500"}
                        fontFamily={"mainFont"}
                    >
                      Total Cost
                    </Text>
                    <Heading
                        color={getCurrentColor(colorMode, "blueC.200", "whiteC.100")}
                        textDecor={"underline"}
                        fontSize={"md "}
                        fontWeight={"700"}
                        fontFamily={"mainFont"}
                        display={"flex"}
                        gap={"6px"}
                        alignItems={"center"}
                        cursor={"pointer"}
                    >
                      <ToolTipTotal
                          totalCost={landed_price}
                          ship_cost={ship_cost}
                          status={ship_flag}
                          price={price}
                          taxes={taxes}
                          isVariant={is_variant === 1}
                          perUnit={per_unit}
                      >
                        $
                        {+landed_cpr % 1 === 0
                            ? (Math.round(landed_cpr * 100) / 100).toFixed(2)
                            : (+landed_cpr).toFixed(2)}{" "}
                        <Text as={"span"} mr={"5px"}>
                          CPR
                        </Text>
                        <Box as={"span"} cursor={"pointer"}>
                          {cautionStatus(ship_flag)?.icon}
                        </Box>
                      </ToolTipTotal>
                    </Heading>
                    <Text
                        color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                        fontSize={"sm"}
                        fontWeight={"400"}
                        fontFamily={"mainFont"}
                    >
                      ${(+landed_price).toFixed(2)}
                    </Text>
                  </ListItem>}
            </List>
          </Flex>
          {!slider && (
              <Flex gap={"8px"} flexWrap={"wrap"} mb={5}>
                {getProductCharacteristic(!isProductsCategoriesMode ? characteristics : characteristics.filter(item => item), colorMode)}
              </Flex>
          )}
        </Flex>
        <Flex justifyContent={"space-between"} gap={2} mt={"auto"}>
          <Flex flexDirection={"column"}>
                <Flex mb={"5px"} flexDirection={"column"}>
                  {slider ? (
                      <Text fontSize="xs" whiteSpace="nowrap">
                        Sold By:{" "}
                        <>
                          {vendor_name?.length > 13 ? (
                              <Tooltip
                                  cursor={"pointer"}
                                  hasArrow
                                  label={vendor_name}
                                  bg="gray.300"
                                  color="black"
                              >
                                {vendor_name?.length > 13
                                    ? `${vendor_name.slice(0, 12)}...`
                                    : vendor_name}
                              </Tooltip>
                          ) : (
                              <Text
                                  fontSize={"13px"}
                                  mb={"5px"}
                                  fontFamily={"mainFont"}
                                  as={"b"}
                              >
                                {vendor_name}
                              </Text>
                          )}
                        </>
                      </Text>
                  ) : (!isProductsCategoriesMode ?
                      <>
                        {vendor_name?.length > 15 ? (
                            <Tooltip
                                cursor={"pointer"}
                                hasArrow
                                label={vendor_name}
                                bg="gray.300"
                                color="black"
                            >
                              {vendor_name?.length > 15
                                  ? `${vendor_name.slice(0, 14)}...`
                                  : vendor_name}
                            </Tooltip>
                        ) : (
                            <Text
                                fontSize={"13px"}
                                mb={"5px"}
                                fontFamily={"mainFont"}
                                as={"b"}
                            >
                              {vendor_name}
                            </Text>
                        )}
                      </>
                  : <></>)}
                </Flex>

            {gb_flag === 1 && !isProductsCategoriesMode && !slider && (
                <Flex gap={"5px"} mb={"7px"}>
                  <Image
                      w={"34px"}
                      loading="lazy"
                      src="https://cdn.bulletscout.com/image/main-page/vendors/12.webp"
                      alt="gun broker"
                  />
                  <Text
                      color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                      fontSize={"xs"}
                      fontFamily={"mainFont"}
                  >
                    via GunBroker
                  </Text>
                </Flex>
            )}
            {(!isProductsCategoriesMode || slider) && <Rating rating={vendor_rating} reviews={vendor_rating_count} />}
          </Flex>

          <Flex width={(isProductsCategoriesMode && !slider) && "100%"} justifyContent={"space-between"}>
            {isProductsCategoriesMode && !slider &&
                <Flex direction={"column"}>
                  <Text
                      color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                      fontSize={14}
                      fontWeight={"400"}
                      fontFamily={"mainFont"}
                  >
                    {listings_count} {pluralize('offer', listings_count)} from
                  </Text>
                  <Text
                      color={getCurrentColor(colorMode, "blueC.100", "whiteC.100")}
                      fontSize={18}
                      fontWeight={"600"}
                      fontFamily={"mainFont"}
                  >
                    ${(+lowest_starts_from).toFixed(2)} CPR
                  </Text>
                </Flex>
            }
            <Link
                to={productGroupLink(product_group_id, cookies.zip)}
                as={RouterLink}
                px={(isProductsCategoriesMode && !slider)  ? "10.5px" : "18px"}
                py={(isProductsCategoriesMode && !slider)  ? "2.5px" : "10px"}
                bg={"blueC.100"}
                _hover={{ bg: "blueC.200" }}
                color={"whiteC.100"}
                borderRadius={"5px"}
                w={(isProductsCategoriesMode && !slider) ? "145px" : "125px"}
                h={(isProductsCategoriesMode && !slider)  ? "" : "40px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={"auto"}
                textAlign={(isProductsCategoriesMode && !slider)  && "center"}
            >
              {(isProductsCategoriesMode && !slider) ? 'View All Offers & Product Info' : 'View'}
            </Link>
          </Flex>
        </Flex>
      </Box>
      {isProductsCategoriesMode && areListingsOpened && !slider &&
      <List>
        {listings?.map((item) => (
          <ListItem
              border={"1px solid"}
              borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.2400")}
              borderRadius={"5px"}
              pt={{ base: "17px", md: recommend_flag === "1" ? 8 : 4 }}
              pb={{ base: 3, md: "16px" }}
              px={5}
              display={{ base: "flex", md: "none" }}
              justifyContent={"space-between"}
              position={"relative"}
              maxW={{ base: "320px", "1xs": "378px", lg: "350px" }}
              boxShadow={
                "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
              }
              width={'100%'}
              bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
              flexDirection={"column"}
              gap={"20px"}
          >
            <Flex>
              <Flex
                  direction={"column"}
                  minW={{ base: "80px", "1xs": "100"}}
              >
                <Text
                    fontSize={"xs"}
                    mb={"5px"}
                    fontFamily={"mainFont"}
                >
                  Rounds
                </Text>
                <Flex gap={1}>
                  <Text
                    fontSize={"s"}
                    mb={"5px"}
                    fontFamily={"mainFont"}
                  >
                    {item.rdsperbox}
                  </Text>
                  {item.is_variant === 1 && <TextTooltip text={`This listing reflects the purchase of multiple units, please make sure to add ${item.per_unit} ${pluralize('unit', per_unit)} to cart.`}>
                    <InfoIcon pl={'3px'}/>
                  </TextTooltip>}
                </Flex>
              </Flex>
              <Flex
                  direction={"column"}
                  minW={{ base: "100px", "1xs": "120"}}
              >
                <Text
                    fontSize={"xs"}
                    mb={"5px"}
                    fontFamily={"mainFont"}
                >
                  Item Cost
                </Text>
                <Text
                    fontSize={"s"}
                    mb={"5px"}
                    fontFamily={"mainFont"}
                >
                  {item.cpr} CPR
                </Text>
                <Text
                    fontSize={"xs"}
                    mb={"5px"}
                    fontFamily={"mainFont"}
                >
                  ${item.price}
                </Text>
              </Flex>
              <Flex
                  direction={"column"}
                  minW={{ base: "110px", "1xs": "130px", lg: "130px" }}
              >
                  <Text
                      color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                      fontSize={"xs"}
                      fontWeight={"500"}
                      mb={"5px"}
                      fontFamily={"mainFont"}
                  >
                    Total Cost
                  </Text>
                  <Heading
                      color={getCurrentColor(colorMode, "blueC.200", "whiteC.100")}
                      textDecor={"underline"}
                      fontSize={"s"}
                      fontWeight={"700"}
                      mb={"7px"}
                      fontFamily={"mainFont"}
                      display={"flex"}
                      gap={"6px"}
                      alignItems={"center"}
                      cursor={"pointer"}
                  >
                    <ToolTipTotal
                        price={item.price}
                        totalCost={item.landed_price}
                        ship_cost={item.ship_cost}
                        status={item.ship_flag}
                        taxes={item.taxes}
                    >
                      $
                      {(+item.landed_cpr).toFixed(2)}{" "}
                      CPR
                      <Box as={"span"} cursor={"pointer"}>
                        {cautionStatus(item.ship_flag).icon}
                      </Box>
                    </ToolTipTotal>
                  </Heading>
                  <Text
                      color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                      fontSize={"xs"}
                      fontWeight={"400"}
                      fontFamily={"mainFont"}
                  >
                    ${(+item.landed_price).toFixed(2)}
                  </Text>
              </Flex>
            </Flex>
            <Flex
              justifyContent={"space-between"}
            >
              <Flex>
                <Flex
                    direction={"column"}
                    maxW={"150px"}
                    w={"100%"}
                    gap={"3px"}
                >
                  {item.vendor_name.length > 15 ? (
                      <Tooltip
                          cursor={"pointer"}
                          hasArrow
                          label={vendor_name}
                          bg="gray.300"
                          color="black"
                          fontSize={"xs"}
                      >
                        <Text
                            fontSize={"xs"}
                            mb={"5px"}
                            fontFamily={"mainFont"}
                        >
                          {item.vendor_name.length > 15
                              ? `Sold by: ${item.vendor_name.slice(0, 14)}...`
                              : `Sold by: ${item.vendor_name}`}
                        </Text>
                      </Tooltip>
                  ) : (
                      <Text fontSize={"xs"} mb={"5px"} fontFamily={"mainFont"}>
                        Sold by: {item.vendor_name}
                      </Text>
                  )}
                  <Rating rating={item.vendor_rating} reviews={item.vendor_rating_count}/>
                </Flex>
                {item.gb_flag === 1 && (
                    <Flex mt={'4px'} gap={"5px"}>
                      <Image
                          w={"34px"}
                          loading="lazy"
                          src="https://cdn.bulletscout.com/image/main-page/vendors/12.webp"
                          alt="gun broker"
                      />
                    </Flex>
                )}
              </Flex>
              <Link
                  href={`${item.affiliate_url}`}
                  target="_blank"
                  px={'14px'}
                  py={"10px"}
                  bg={"blueC.100"}
                  _hover={{ bg: "blueC.200" }}
                  color={"whiteC.100"}
                  borderRadius={"5px"}
                  w={'100px'}
                  h={"50px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
              >
                View
              </Link>
            </Flex>
          </ListItem>
        ))}
      </List>
      }
      {isProductsCategoriesMode && !slider &&
          <Flex
              display={{ base: "flex", md: "none" }}
              color={getCurrentColor(colorMode, "blackC.300", "whiteC.100")}
              bg={getCurrentColor(colorMode, "grayC.400", "grayC.3000")}
              justifyContent={"center"}
              alignItems={"center"}
              m={0}
              maxW={{ base: "320px", "1xs": "378px", lg: "350px" }}
              width="100%"
              fontSize={16}
              pt={'4px'}
              pb={'4px'}
              onClick={handleOpenListingsToggle}
              cursor={"pointer"}
          >
            <Text
                fontWeight={600}
            >
              {areListingsOpened ? 'Collapse Top Offers' : 'Preview Top Offers'}
              {areListingsOpened ?
                  <ChevronUpIcon
                      color={getCurrentColor(colorMode, "blackC.300", "grayC.1600")}
                      w={"24px"}
                      h={"24px"}
                      cursor={"pointer"}
                      pb={'2px'}
                  />
                  :
                  <ChevronDownIcon
                      color={getCurrentColor(colorMode, "blackC.300", "grayC.1600")}
                      w={"24px"}
                      h={"24px"}
                      cursor={"pointer"}
                      pb={'2px'}
                  />
              }
            </Text>
          </Flex>
      }
    </ListItem>
  );
};

export default ProductItemMobile;
