import { Flex, Image, List, Text, useColorMode } from "@chakra-ui/react";
import { lazy, Suspense, useEffect } from "react";
import ProductSkeletonDesctop from "../../skeletons/Products/ProductSkeletonDesktop";
import ProductSkeletonMobile from "../../skeletons/Products/ProductSkeletonMobile";
import Pagination from "../Pagination/Pagination";
import PriceHistory from "../PriceHistory/PriceHistory";
import {useLocation, useSearchParams} from "react-router-dom";
import useSearchParamsHandler from "../../hooks/useSearchParamsHandler";

const ProductItem = lazy(() => import("../ProductItem/ProductItem"));

const ProductList = ({ slider, currentPage, allPages, productsInfo, productsLoadingStatus }) => {
  const { colorMode } = useColorMode();

  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const { params,  } = useSearchParamsHandler();

  const fullUrl = location.pathname + location.search;

  if(!slider){
      localStorage.setItem('fullUrl', fullUrl);
  }

  useEffect(() => {
      if(!slider){
          setSearchParams({...params, sf: '1,2'})
      }
  }, [location.pathname])

  const renderProductsSkeleton = () => (
    <Flex
      flexDirection={{ base: "row", xl: "column" }}
      flexWrap={"wrap"}
      gap={"3px"}
      justifyContent={"space-around"}
    >
      {[...Array(30)].map((item, index) =>
        window.screen.width > 768 ? (
          <ProductSkeletonDesctop key={index} />
        ) : (
          <ProductSkeletonMobile key={index} />
        ),
      )}
    </Flex>
  );

  const renderNotFound = (
    <Flex flexDirection={"column"} alignItems={"center"}>
      <Text>No products were found for these filters</Text>
      <Image
        alt="Not found"
        w={"70%"}
        src={
          colorMode === "light"
            ? "https://cdn.bulletscout.com/image/not-found.png"
            : "https://cdn.bulletscout.com/image/not-found-dark1.png"
        }
      />
    </Flex>
  );

  return (
    <Flex flexDirection={"column"} w={"100%"}>
      {productsLoadingStatus === "loading" ? renderProductsSkeleton() : null}

      {productsLoadingStatus !== "loading" &&
        productsInfo &&
        productsInfo.length <= 0 &&
        renderNotFound}

      <Suspense fallback={renderProductsSkeleton()}>
        <List
          mb={{ base: "58px", md: "52px" }}
          maxWidth={"1015px"}
          w={"100%"}
          display={"flex"}
          flexDirection={{ base: "column", lg: "row", "xl": "column" }}
          justifyContent={{ base: "space-around", xl: "flex-start" }}
          flexWrap={"wrap"}
          gap={3}
          ml={"auto"}
        >
          {productsInfo &&
            productsInfo.map((product, i) => (
              <ProductItem
                key={i}
                product={product}
                slider={slider}
              />
            ))}
        </List>
      </Suspense>

      {productsInfo && Object.values(productsInfo).length > 0 && currentPage && allPages && (
        <Pagination slider={slider}/>
      )}

      {slider && <PriceHistory/>}
    </Flex>
  );
};

export default ProductList;
