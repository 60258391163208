import {
  Box,
  Button,
  Flex,
  Select,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { TriangleUpIcon } from "@chakra-ui/icons";
import { getCurrentColor } from "../../constants";
import useHttp from "../../hooks/useHttp";

const ToolTipIssue = ({ children, id, isVariant }) => {
  const { postProductReport } = useHttp();
  const [showToolTip, setShowToolTip] = useState(false);
  const [selectProblem, setSelectProblem] = useState("Pricing error");
  const { colorMode } = useColorMode();
  const [messageStatus, setMessageStatus] = useState("");
  const [sendReport, setSendReport] = useState(false);

  const tooltipRef = useRef(null);
  const triggerRef = useRef(null);

  const onSendReport = (data) => {
    setSendReport(true);
    postProductReport(data)
      .then((res) => {
        if (res.status === 200) {
          setSendReport(false);
          setMessageStatus("Thank you for reporting this listing. It has been received and will be reviewed by our team");
          setTimeout(() => {
            setMessageStatus("");
          }, 3000);
        } else {
          setMessageStatus("Report didn't send. Please try again!");
          setTimeout(() => {
            setMessageStatus("");
          }, 3000);
        }
      })
      .catch(() => {
        setSendReport(false);
        setMessageStatus("Report didn't send. Please try again!");
        setTimeout(() => {
          setMessageStatus("");
        }, 3000);
      });
  };

  const handleClickOutside = (event) => {
    if (
      tooltipRef.current &&
      !tooltipRef.current.contains(event.target) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target)
    ) {
      setShowToolTip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTriggerClick = () => {
    setShowToolTip((prev) => !prev);
  };

  return (
    <Box position={"relative"} cursor={"auto"}>
      <Box onClick={handleTriggerClick} ref={triggerRef}>
        {children}
      </Box>

      {showToolTip && (
        <Flex
          flexDirection={"column"}
          position={"absolute"}
          bottom={{ base: 5, md: 5 }}
          right={{ base: "-20px", md: "25px" }}
          zIndex={98}
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
          w={{ base: "287px", md: "298px" }}
          borderRadius={"5px"}
          boxShadow={"0px 4px 15px 0px rgba(0, 0, 0, 0.15)"}
          ref={tooltipRef}
        >
          <Flex
            gap={"6px"}
            fontSize={"md"}
            color={getCurrentColor(colorMode, "blackC.200", "whiteC.200")}
            fontWeight={"600"}
            bg={getCurrentColor(colorMode, "grayC.1100", "blueC.500")}
            px={3}
            pt={3}
            pb={"14px"}
            borderTopRadius={"5px"}
            border={"1px solid "}
            borderColor={getCurrentColor(colorMode, "grayC.1200", "grayC.2400")}
            borderBottom={"none"}
          >
            Report Issue
          </Flex>
          <Flex
            flexDirection={"column"}
            bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
            px={"18px"}
            py={3}
            color={"grayC.200"}
            fontSize={"sm"}
            fontWeight={"600"}
            borderBottomRadius={"5px"}
            position={"relative"}
            border={"1px solid"}
            borderColor={getCurrentColor(colorMode, "grayC.1200", "grayC.2400")}
          >
            {messageStatus.length === 0 ? (
              <Select
                value={selectProblem}
                onChange={(e) => setSelectProblem(e.target.value)}
                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                placeholder="Select an issue to report"
                mb={6}
              >
                <option value="Pricing error">Pricing error</option>
                <option value="Incorrect listing information">
                  Incorrect listing information
                </option>
                <option value="Out of stock">Out of stock</option>
              </Select>
            ) : (
              <Text
                mb={4}
                mt={2}
                color={getCurrentColor(colorMode, "blackC.200", "whiteC.200")}
                fontWeight={"600"}
                fontSize={"sm"}
              >
                {messageStatus}
              </Text>
            )}
            <Flex
              position={"relative"}
              zIndex={99}
              justifyContent={"space-between"}
            >
              <Button
                w={"118px"}
                variant={"outline"}
                onClick={() => setShowToolTip(false)}
              >
                Cancel
              </Button>
              <Button
                w={"125px"}
                bg={"blueC.100"}
                color={"whiteC.100"}
                isLoading={sendReport}
                _hover={{ bg: "blueC.200" }}
                onClick={() =>
                  onSendReport({
                    product_id: id + "",
                    type_of_issue: selectProblem,
                    is_variant: isVariant
                  })
                }
              >
                Report Issue
              </Button>
            </Flex>
            <Box
              zIndex={1}
              position={"absolute"}
              bottom={{ base: "-11px", md: "-4.7px" }}
              right={{ base: "10px", md: "-11.5px" }}
              transform={{ base: "rotate(90deg)", md: "rotate(0deg)" }}
            >
              <TriangleUpIcon
                sx={{
                  path: {
                    stroke: getCurrentColor(
                      colorMode,
                      "grayC.1200",
                      "grayC.2400",
                    ),
                    strokeWidth: "1px",
                  },
                }}
                color={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
                w={"23px"}
                h={"20px"}
                borderRadius={"20px"}
              />
              <Box
                bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
                position={"absolute"}
                top={{ base: "3px", md: "1.8px" }}
                left={{ base: "-1.5px", md: "-1.5px" }}
                w={"13px"}
                h={"15px"}
              ></Box>
            </Box>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default ToolTipIssue;
