import { DangerousIcon, MediumIcon, VettedIcon } from "../assets/icons/icons";

export const cautionStatus = (caution) => {
  if (+caution === 1) {
    return {
      icon: <VettedIcon />,
      title: "Shipping Cost Vetted",
      text: "We have thoroughly vetted the shipping rates provided by this vendor and stand behind our estimation.",
    };
  } else if (+caution === 2) {
    return {
      icon: <MediumIcon />,
      title: "Actual Shipping May Differ",
      text: "Caution: this vendor's shipping cost estimation may not be fully accurate, please verify at checkout before purchasing.",
    };
  } else if (+caution === 3) {
    return {
      icon: <DangerousIcon />,
      title: "Shipping Cost Unknown",
      text: "We are unable to determine this vendor's shipping costs with any degree of confidence.",
    };
  }

  return {
    icon: '',
    title: '',
    text: ''
  }
};
