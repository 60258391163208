import { Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { getCurrentColor, offsetTopStatus } from "../../constants";

const TextTooltip = ({
                          children,
                          text
                      }) => {
    const [showToolTip, setShowToolTip] = useState(false);
    const { colorMode } = useColorMode();
    const elementRef = useRef(null);
    const [offsetTop, setOffsetTop] = useState(0);

    const controlScroll = () => {
        setShowToolTip(false);
        if (elementRef.current) {
            const rect = elementRef.current.getBoundingClientRect();
            setOffsetTop(rect.top);
        }
    };

    useEffect(() => {
        document.addEventListener("scroll", controlScroll);

        return () => {
            document.removeEventListener("scroll", controlScroll);
        };
    }, []);

    return (
        <Box
            position={"relative"}
            onMouseOver={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
            cursor={"pointer"}
            ref={elementRef}
        >
            <Text display={"flex"} alignItems={"center"} gap={"6px"}>
                {children}
            </Text>

            {showToolTip && (
                <Flex
                    flexDirection={"column"}
                    position={"absolute"}
                    bottom={offsetTopStatus(offsetTop) ? "auto" : 6}
                    top={offsetTopStatus(offsetTop) ? { base: 7, md: 6 } : "auto"}
                    right={{ base: "-200px", md: "0px" }}
                    zIndex={100}
                    bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
                    w={{ base: "287px", md: "298px" }}
                    borderRadius={"5px"}
                    boxShadow={"0px 4px 15px 0px rgba(0, 0, 0, 0.15)"}
                    cursor={"auto"}
                >
                    <Box
                        position={"absolute"}
                        bottom={offsetTopStatus(offsetTop) ? "auto" : "-15px"}
                        top={offsetTopStatus(offsetTop) ? "-20px" : "auto"}
                        right={offsetTopStatus(offsetTop) ? "7px" : 0}
                        zIndex={99}
                        bg={"transparent"}
                        w={"110px"}
                        h={"20px"}
                    ></Box>
                    <Flex
                        gap={"6px"}
                        fontSize={"md"}
                        color={getCurrentColor(colorMode, "blackC.200", "whiteC.200")}
                        fontWeight={"400"}
                        bg={getCurrentColor(colorMode, "grayC.1100", "blueC.500")}
                        px={3}
                        pt={3}
                        pb={"14px"}
                        borderTopRadius={"5px"}
                        border={"1px solid"}
                        borderColor={getCurrentColor(colorMode, "grayC.1200", "grayC.2400")}
                        borderBottom={"none"}
                    >
                        {text}
                    </Flex>
                </Flex>
            )}
        </Box>
    );
};

export default TextTooltip;
