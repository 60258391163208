import {Button, Flex, FormControl, FormErrorMessage, Input, Text, useColorMode} from "@chakra-ui/react";
import {emailReg, getCurrentColor} from "../../constants";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import useHttp from "../../hooks/useHttp";

const MainPageNewsLetter = () => {
    const [, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [ip, setIp] = useState("");
    const { colorMode } = useColorMode();
    const [messageStatus, setMessageStatus] = useState(false);
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
        clearErrors,
    } = useForm();

    const { postNewsLetter } = useHttp();

    useEffect(()=>{
        fetch('https://api.ipify.org?format=json').then(response => {
            return response.json();
        }).then((res) => {
            setIp(res.ip)
        }).catch((err) => console.error('Problem fetching my IP', err))
    },[])

    const onSubmit = (values) => {
        setMessageStatus(true);
        postNewsLetter({...values, ip_address: ip})
            .then((res) => {
                if (res.status !== "success") {
                    setError(true);
                } else {
                    setSuccess(true);
                    clearErrors();
                }
            })
            .catch(() => setError(true))
            .finally(() => {
                setValue("email", "");
                setMessageStatus(false);
            });
    };

    const handleChange = (e) => {
        if (e.target.value.length <= 1) {
            const newValue = e.target.value.trim();
            setValue(e.target.getAttribute("id"), newValue, { shouldValidate: true });
        } else {
            setValue(e.target.getAttribute("id"), e.target.value, {
                shouldValidate: true,
            });
        }
    };

    return (
        <Flex
            pt={"22px"}
            pb={"20px"}
            maxW={"660px"}
            mx={"auto"}
            px={{ base: "22px", md: "0px" }}
            alignItems={"center"}
            justifyContent={"center"}
            gap={5}
            direction={{
                base: "column",
                md: "row"
            }}
        >
            <Text
                textAlign={"center"}
                mb={"25px"}
                fontSize={"lg"}
                fontFamily={"mainFont"}
                fontWeight={"400"}
                color={getCurrentColor(colorMode, "grayC.100", "grayC.1600")}
            >
                Sign up for our newsletter!
            </Text>
            <form onSubmit={handleSubmit(onSubmit)} style={{
                display: "flex",
                gap: "10px"
            }}>
                <FormControl mb={"25px"} isInvalid={errors.email}>
                    <Input
                        id="email"
                        fontFamily={"mainFont"}
                        fontSize={"sm"}
                        name="email"
                        placeholder="Email Address"
                        bg={getCurrentColor(colorMode, "whiteC.100", "grayC.2000")}
                        borderColor={getCurrentColor(
                            colorMode,
                            "grayC.500",
                            "grayC.2200",
                        )}
                        {...register("email", {
                            required: "This is required",
                            pattern: {
                                value: emailReg,
                                message: "Invalid email address",
                            },
                            onChange: handleChange,
                        })}
                    />
                    <FormErrorMessage
                        fontSize={"xs"}
                        bottom={"-20px"}
                        position={"absolute"}
                    >
                        {errors.email && errors.email.message}
                    </FormErrorMessage>
                </FormControl>
                {success ?
                    <Text
                        textAlign={"center"}
                        fontSize={"lg"}
                        fontFamily={"mainFont"}
                        fontWeight={"400"}
                        color={getCurrentColor(colorMode, "grayC.100", "grayC.1600")}
                        width={'150px'}
                    >
                        Thank you!
                    </Text>
                :
                    <Button
                        display={"flex"}
                        gap={2.5}
                        alignItems={"center"}
                        bg={"blueC.100"}
                        color={"whiteC.100"}
                        isLoading={messageStatus}
                        type="submit"
                        w={"100%"}
                        _hover={{
                            bg: "blueC.200",
                        }}
                    >
                        Submit
                    </Button>}
            </form>
        </Flex>
    );
};

export default MainPageNewsLetter;