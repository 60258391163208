import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import useSearchParamsHandler from "./useSearchParamsHandler";
import {
    allPagesAtom,
    allProductsAtom,
    currentPageAtom,
    fetchProductsAtom,
    productPerPageAtom,
    productsInfoAtom,
    productsLoadingStatusAtom,
} from "../atom/products";
import { useAtom } from "jotai";

const useUpdateProducts = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { params } = useSearchParamsHandler();
    const [, fetchProducts] = useAtom(fetchProductsAtom);
    const [productsLoadingStatus, setProductsLoadingStatus] = useAtom(
        productsLoadingStatusAtom,
    );
    const [productsInfo] = useAtom(productsInfoAtom);
    const [currentPage] = useAtom(currentPageAtom);
    const [allPages] = useAtom(allPagesAtom);
    const [productPerPage] = useAtom(productPerPageAtom);
    const [allProducts] = useAtom(allProductsAtom);

    const removeWcFromSearch = useCallback(() => {
        const params = new URLSearchParams(location.search);
        params.delete("wc");
        return params.toString();
    }, [location.search]);

    const settingProducts = useCallback(async (location) => {
        try {
            setProductsLoadingStatus("loading");
            await fetchProducts(location);
        } catch (e) {
            console.error(e);
        }
    }, []);

    useEffect(() => {
        if (!location.search.includes(`page=${currentPage}`)) {
            setSearchParams({ ...params, page: currentPage });
        }
    }, [currentPage]);

    useEffect(() => {
        window.scrollTo(0, 0);

        const updatedSearch = removeWcFromSearch();
        settingProducts(updatedSearch.startsWith("?") ? updatedSearch.slice(1) : updatedSearch);
    }, [searchParams, removeWcFromSearch, settingProducts]);

    const data = useMemo(
        () => ({
            currentPage,
            allPages,
            productsInfo,
            productPerPage,
            allProducts,
            productsLoadingStatus,
        }),
        [
            currentPage,
            allPages,
            productsInfo,
            productPerPage,
            allProducts,
            productsLoadingStatus,
        ],
    );

    return data;
};

export default useUpdateProducts;
