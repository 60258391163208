import { Box, Flex, Text, useColorMode } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { cautionStatus } from "../../utils/cautionStatus";
import { TriangleUpIcon } from "@chakra-ui/icons";
import { getCurrentColor, offsetTopStatus } from "../../constants";
import pluralize from "pluralize";

const ToolTipTotal = ({
  children,
  totalCost,
  status,
  ship_cost,
  price,
  taxes,
  isVariant,
  perUnit
}) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const { colorMode } = useColorMode();
  const elementRef = useRef(null);
  const [offsetTop, setOffsetTop] = useState(0);

  const controlScroll = () => {
    setShowToolTip(false);
    if (elementRef.current) {
      const rect = elementRef.current.getBoundingClientRect();
      setOffsetTop(rect.top);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", controlScroll);

    return () => {
      document.removeEventListener("scroll", controlScroll);
    };
  }, []);

  return (
    <Box
      position={"relative"}
      onMouseOver={() => setShowToolTip(true)}
      onMouseLeave={() => setShowToolTip(false)}
      cursor={"pointer"}
      ref={elementRef}
    >
      <Text display={"flex"} alignItems={"center"} gap={"6px"}>
        {children}
      </Text>

      {showToolTip && (
        <Flex
          flexDirection={"column"}
          position={"absolute"}
          bottom={offsetTopStatus(offsetTop) ? "auto" : 6}
          top={offsetTopStatus(offsetTop) ? { base: 7, md: 6 } : "auto"}
          right={{ base: "0px", md: "0px" }}
          zIndex={100}
          bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
          w={{ base: "287px", md: "298px" }}
          borderRadius={"5px"}
          boxShadow={"0px 4px 15px 0px rgba(0, 0, 0, 0.15)"}
          cursor={"auto"}
        >
          <Box
            position={"absolute"}
            bottom={offsetTopStatus(offsetTop) ? "auto" : "-15px"}
            top={offsetTopStatus(offsetTop) ? "-20px" : "auto"}
            right={offsetTopStatus(offsetTop) ? "7px" : 0}
            zIndex={99}
            bg={"transparent"}
            w={"110px"}
            h={"20px"}
          ></Box>
          <Flex
            gap={"6px"}
            fontSize={"md"}
            color={getCurrentColor(colorMode, "blackC.200", "whiteC.200")}
            fontWeight={"600"}
            bg={getCurrentColor(colorMode, "grayC.1100", "blueC.500")}
            px={3}
            pt={3}
            pb={"14px"}
            borderTopRadius={"5px"}
            border={"1px solid"}
            borderColor={getCurrentColor(colorMode, "grayC.1200", "grayC.2400")}
            borderBottom={"none"}
          >
            Total Cost Breakdown
          </Flex>
          <Flex
            flexDirection={"column"}
            bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
            px={"18px"}
            py={3}
            color={getCurrentColor(colorMode, "grayC.200", "grayC.1600")}
            fontSize={"sm"}
            fontWeight={"600"}
            borderBottomRadius={"5px"}
            position={"relative"}
            border={"1px solid #F5F9FF"}
            borderColor={getCurrentColor(colorMode, "grayC.1200", "grayC.2400")}
          >
            <Flex
              flexDirection={"column"}
              gap={"6px"}
              borderBottom={"1px solid"}
              borderColor={"grayC.1200"}
              pb={3}
            >
              <Text
                as={"p"}
                w={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                Item Cost:{" "}
                <Text ml={"auto"} as={"b"}>
                  ${(+price).toFixed(2)}
                </Text>
              </Text>
              {ship_cost && (
                <Text
                  w={"100%"}
                  as={"p"}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  Shipping:{" "}
                  <Text ml={"auto"} as={"b"}>
                    ${(+ship_cost).toFixed(2)}
                  </Text>
                </Text>
              )}
            </Flex>
            <Flex
              borderBottom={"1px solid"}
              borderColor={"grayC.1200"}
              pt={3}
              pb={3}
              flexDirection={"column"}
              gap={"4px"}
            >
              <Text
                as={"p"}
                w={"100%"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                Total:{" "}
                <Text ml={"auto"} as={"b"}>
                  ${(+totalCost).toFixed(2)}
                </Text>
              </Text>
              <Text fontSize={"xs"} fontFamily={"mainFont"} fontWeight={"400"}>
                Shipping cost may vary on vendor’s site.
              </Text>
            </Flex>
            {
              isVariant &&
                <Text fontSize={"xs"} fontFamily={"mainFont"} fontWeight={"400"} pt={2}>
                  This listing reflects the purchase of multiple units, please make sure to add {perUnit} {pluralize('unit', perUnit)} to cart.
                </Text>
            }
            <Flex gap={1.5} pt={3} fontSize={"xs"} fontFamily={"mainFont"}>
              {cautionStatus(status)?.icon}
              {cautionStatus(status)?.text}
            </Flex>
            <Box
              zIndex={1}
              position={"absolute"}
              bottom={
                offsetTopStatus(offsetTop)
                  ? "auto"
                  : { base: "-9.7px", md: "-9.7px" }
              }
              top={
                offsetTopStatus(offsetTop)
                  ? { base: "-58.5px", "1xs": "-56px" }
                  : "auto"
              }
              right={
                offsetTopStatus(offsetTop)
                  ? { base: "45px", md: "20px" }
                  : { base: "45px", md: "0px" }
              }
              transform={
                offsetTopStatus(offsetTop)
                  ? { base: "rotate(-90deg)", md: "rotate(-90deg)" }
                  : { base: "rotate(90deg)", md: "rotate(90deg)" }
              }
            >
              <TriangleUpIcon
                sx={{
                  path: {
                    stroke: getCurrentColor(
                      colorMode,
                      "grayC.1200",
                      "grayC.2400",
                    ),
                    strokeWidth: "1px",
                  },
                }}
                color={
                  offsetTopStatus(offsetTop)
                    ? getCurrentColor(colorMode, "grayC.1100", "blueC.500")
                    : getCurrentColor(colorMode, "whiteC.100", "grayC.1700")
                }
                w={"23px"}
                h={"20px"}
                borderRadius={"20px"}
              />
              <Box
                bg={
                  offsetTopStatus(offsetTop)
                    ? getCurrentColor(colorMode, "grayC.1100", "blueC.500")
                    : getCurrentColor(colorMode, "whiteC.100", "grayC.1700")
                }
                position={"absolute"}
                top={{ base: "1.5px", md: "1.5px" }}
                left={{ base: "-0.8px", md: "-0.8px" }}
                w={"13px"}
                h={"17px"}
              ></Box>
            </Box>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default ToolTipTotal;
