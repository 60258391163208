import MainPageSectionBrands from "../components/MainPageSections/MainPageSectionBrands";
import MainPageAboutUs from "../components/MainPageSections/MainPageAboutUs";

import MainPageForm from "../components/MainPageSections/MainPageForm";

import { Box } from "@chakra-ui/react";

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import MainPageDiscoverSection from "../components/MainPageSections/MainPageDiscoverSection";
import MainPageNewsLetter from "../components/MainPageSections/MainPageNewsLetter";

const MainPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const [brandsLoaded, setBrandsLoaded] = useState(false);
  const [aboutUsLoaded, setAboutUsLoaded] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [newsLetterLoaded, setNewsLetterLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await import("../components/MainPageSections/MainPageSectionBrands");
      setBrandsLoaded(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await import("../components/MainPageSections/MainPageAboutUs");
      setAboutUsLoaded(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await import("../components/MainPageSections/MainPageForm");
      setFormLoaded(true);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await import("../components/MainPageSections/MainPageNewsLetter");
      setNewsLetterLoaded(true);
    };
    fetchData();
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Bullet Scout</title>
        <meta
          name="description"
          content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
        />
      </Helmet>
      <MainPageDiscoverSection />
      {brandsLoaded && <MainPageSectionBrands />}
      {aboutUsLoaded && <MainPageAboutUs />}
      {formLoaded && <MainPageForm />}
      {newsLetterLoaded && <MainPageNewsLetter />}
    </Box>
  );
};

export default MainPage;
