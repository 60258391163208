import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Text,
  Tooltip,
  useColorMode,
} from "@chakra-ui/react";
import Rating from "../Rating/Rating";
import ToolTipTotal from "../ToolTip/ToolTipTotal";
import { cautionStatus } from "../../utils/cautionStatus";
import ToolTipIssue from "../ToolTip/ToolTipIssue";
import {FlagIcon, InfoIcon} from "../../assets/icons/icons";
import { getProductCharacteristic } from "../../utils/getProductCharacteristic";
import {getCurrentColor, productGroupLink} from "../../constants";
import {useAtom} from "jotai/index";
import {categoriesPageModeAtom} from "../../atom/listings";
import pluralize from "pluralize";
import {ChevronDownIcon, ChevronUpIcon} from "@chakra-ui/icons";
import {useEffect, useState} from "react";
import useCookie from "../../hooks/useCookie";
import { Link as RouterLink } from "react-router-dom";
import TextTooltip from "../ToolTip/Tooltip";

const ProductItemDesktop = ({ productData, recomendedBorder, slider }) => {
  const [categoriesPageMode] = useAtom(categoriesPageModeAtom);
  const isProductsCategoriesMode = categoriesPageMode === 'products';
  const [areListingsOpened, setAreListingsOpened] = useState(false);
  const { cookies } = useCookie();

  useEffect(() => {
    setAreListingsOpened(false)
  }, [productData]);

  const handleOpenListingsToggle = () => {
    setAreListingsOpened(!areListingsOpened);
  }

  const {
    product_id,
    price,
    cpr,
    landed_price,
    landed_cpr,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
    title,
    condition,
    vendor_name,
    vendor_rating,
    ship_flag,
    brand_image_w,
    brand_image_b,
    affiliate_url,
    recommend_flag,
    vendor_rating_count,
    gb_flag,
    ship_cost,
    taxes,
    shell_length,
    weapon_category,
    lowest_starts_from,
    listings,
    product_group_id,
    listing_id,
    listings_count,
    is_variant,
    per_unit
  } = productData;

  const characteristics = [
    weapon_category === "Shotgun" && shell_length,
    condition,
    bullet_type,
    bullet_type_s,
    bullet_weight,
    material,
    rdsperbox,
    caliber,
  ];

  const { colorMode } = useColorMode();

  if(slider && !listing_id) return;

  return (
      <ListItem
          display={{ base: "none", md: "block" }}
          position={"relative"}
          w={"100%"}
      >
        <Box
            border={recommend_flag === "1" ? "2px solid" : "1px solid"}
            borderColor={
              recommend_flag === "1"
                  ? "blueC.100"
                  : getCurrentColor(colorMode, "grayC.800", "grayC.2400")
            }
            borderRadius={"5px"}
            pt={{ base: "17px", md: recommend_flag === "1" ? 8 : 4 }}
            pb={{ base: 3, md: "16px" }}
            px={5}
            display={{ base: "none", md: "flex" }}
            justifyContent={"space-between"}
            position={"relative"}
            w={"100%"}
            boxShadow={
              "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
            }
            bg={getCurrentColor(colorMode, "whiteC.100", "grayC.1700")}
        >
          {recommend_flag === "1" && recomendedBorder()}

          {!slider && (
              <Box maxW={isProductsCategoriesMode ? '185px' : '140px'} w={"100%"}>
                <Image
                    src={getCurrentColor(colorMode, brand_image_w, brand_image_b)}
                    mb={"7px"}
                    w={isProductsCategoriesMode ? '170px' : "125px"}
                    objectFit={"scale-down"}
                    h={!isProductsCategoriesMode ? "40px" : "80px"}
                    loading="lazy"
                    alt={`${title}`}
                />
                <Text fontWeight={"600"} mb={"5px"} fontFamily={"mainFont"}></Text>
                {!isProductsCategoriesMode &&
                    <Flex flexDirection={"column"}>
                      {vendor_name?.length > 15 ? (
                          <Tooltip
                              cursor={"pointer"}
                              hasArrow
                              label={vendor_name}
                              bg="gray.300"
                              color="black"
                              fontSize={"xs"}
                          >
                            <Text
                                fontSize={"xs"}
                                mb={"5px"}
                                fontFamily={"mainFont"}
                                as={"b"}
                            >
                              {vendor_name.length > 15
                                  ? `${vendor_name.slice(0, 14)}...`
                                  : vendor_name}
                            </Text>
                          </Tooltip>
                      ) : (
                          <Text fontSize={"xs"} mb={"5px"} fontFamily={"mainFont"} as={"b"}>
                            {vendor_name}
                          </Text>
                      )}
                    </Flex>
                }
                {!isProductsCategoriesMode &&
                    <Rating rating={vendor_rating} reviews={vendor_rating_count} />
                }
                {!isProductsCategoriesMode && gb_flag === 1 && (
                    <Flex gap={"5px"} mb={"7px"}>
                      <Image
                          w={"34px"}
                          loading="lazy"
                          src="https://cdn.bulletscout.com/image/main-page/vendors/12.webp"
                          alt="gun broker"
                      />
                      <Text
                          fontSize={"xs"}
                          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                          fontFamily={"mainFont"}
                      >
                        via GunBroker
                      </Text>
                    </Flex>
                )}
              </Box>
          )}

          <Flex flexDirection={"column"} w={"100%"}>
            <Flex
                w={"100%"}
                justifyContent={"space-between"}
                gap={7}
                mb={slider ? "" : "12px"}
            >
              <List
                  display={"flex"}
                  w={"100%"}
                  gap={3}
                  justifyContent={"space-between"}
              >
                {slider ? (
                    <ListItem minW={"185px"}>
                      <Text fontSize="xs" whiteSpace="nowrap" mb="5px">
                        Sold By:{" "}
                        <Text as="span" fontWeight="500">
                          {vendor_name}
                        </Text>
                      </Text>

                      <Rating rating={vendor_rating} reviews={vendor_rating_count} />
                      {gb_flag === 1 && (
                          <Flex mt={'4px'} gap={"5px"}>
                            <Image
                                w={"34px"}
                                loading="lazy"
                                src="https://cdn.bulletscout.com/image/main-page/vendors/12.webp"
                                alt="gun broker"
                            />
                            <Text
                                fontSize={"xs"}
                                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                                fontFamily={"mainFont"}
                            >
                              via GunBroker
                            </Text>
                          </Flex>
                      )}
                    </ListItem>
                ) : (
                    <ListItem maxW={{
                      xl: isProductsCategoriesMode ? "500px" : "300px",
                      base: '300px'
                    }} w={"100%"}>
                      {!isProductsCategoriesMode && !slider &&
                          <Text
                              color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                              fontSize={"xs"}
                              fontWeight={"500"}
                              fontFamily={"mainFont"}
                          >
                            Description
                          </Text>
                      }
                      <Heading
                          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                          fontSize={{ base: "xs", lg: "sm", xl: isProductsCategoriesMode ? "lg" : 'md' }}
                          fontWeight={"600"}
                          fontFamily={"mainFont"}
                      >
                        {title}
                      </Heading>
                    </ListItem>
                )}

                {(!isProductsCategoriesMode || slider) &&
                    <ListItem minW={"50px"}>
                      <Text
                          color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                          fontSize={"xs"}
                          fontWeight={"500"}
                          fontFamily={"mainFont"}
                      >
                        Rounds
                      </Text>
                        {is_variant === 1 ?
                            <Flex gap={1}>
                              <Heading
                                  color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                                  fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                                  fontWeight={"500"}
                                  fontFamily={"mainFont"}
                                  display={"flex"}
                                  gap={"6px"}
                                  alignItems={"center"}
                              >
                                {rdsperbox}
                              </Heading>
                            <TextTooltip text={`This listing reflects the purchase of multiple units, please make sure to add ${per_unit} ${pluralize('unit', per_unit)} to cart.`}>
                              <InfoIcon pl={'3px'}/>
                            </TextTooltip>
                            </Flex> :
                            <Heading
                                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                                fontSize={{ base: "xs", lg: "md" }}
                                fontWeight={"500"}
                                fontFamily={"mainFont"}
                            >
                              {rdsperbox}
                            </Heading>
                        }
                    </ListItem>
                }

                {(!isProductsCategoriesMode || slider) &&
                    <ListItem minW={"90px"}>
                      <Text
                          color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                          fontSize={"xs"}
                          fontWeight={"500"}
                          fontFamily={"mainFont"}
                      >
                        Item Cost
                      </Text>
                      <Heading
                          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                          fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                          fontWeight={"500"}
                          fontFamily={"mainFont"}
                      >
                        ${(+cpr).toFixed(2)} CPR
                      </Heading>
                      <Text
                          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                          fontSize={"13px"}
                          fontWeight={"400"}
                          fontFamily={"mainFont"}
                      >
                        ${(+price).toFixed(2)}
                      </Text>
                    </ListItem>
                }

                {(!isProductsCategoriesMode || slider) &&
                    <ListItem minW={"125px"}>
                      <Text
                          color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                          fontSize={"xs"}
                          fontWeight={"500"}
                          fontFamily={"mainFont"}
                      >
                        Total Cost
                      </Text>
                      <Heading
                          color={getCurrentColor(colorMode, "blueC.200", "whiteC.100")}
                          textDecor={"underline"}
                          fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                          fontWeight={"700"}
                          fontFamily={"mainFont"}
                          display={"flex"}
                          gap={"6px"}
                          alignItems={"center"}
                          cursor={"pointer"}
                      >
                        <ToolTipTotal
                            price={price}
                            totalCost={landed_price}
                            ship_cost={ship_cost}
                            status={ship_flag}
                            taxes={taxes}
                            isVariant={is_variant === 1}
                            perUnit={per_unit}
                        >
                          $
                          {landed_cpr}{" "}
                          CPR
                          <Box as={"span"} cursor={"pointer"}>
                            {cautionStatus(ship_flag)?.icon}
                          </Box>
                        </ToolTipTotal>
                      </Heading>
                      <Text
                          color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                          fontSize={"13px"}
                          fontWeight={"400"}
                          fontFamily={"mainFont"}
                      >
                        ${(+landed_price).toFixed(2)}
                      </Text>
                    </ListItem>
                }
              </List>
              {(!isProductsCategoriesMode || slider) &&
                  <Link
                      href={`${affiliate_url}`}
                      target="_blank"
                      px={'18px'}
                      py={"10px"}
                      marginBottom={isProductsCategoriesMode ? '15px' : '0'}
                      bg={"blueC.100"}
                      _hover={{ bg: "blueC.200" }}
                      color={"whiteC.100"}
                      borderRadius={"5px"}
                      w={'125px'}
                      h={"40px"}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      alignSelf={'center'}
                  >
                    View
                  </Link>
              }
            </Flex>
            {!slider &&(
                <Flex gap={"8px"} flexWrap={"wrap"} maxW={"620px"}>
                  {getProductCharacteristic(!isProductsCategoriesMode ? characteristics : characteristics.filter(item => item), colorMode)}
                </Flex>
            )}
          </Flex>
          {isProductsCategoriesMode && !slider &&
              <Flex
                  direction={"column"}
                  justifyContent={"space-between"}
              >
                <Flex
                    alignItems={"center"}
                    gap={2}
                >
                  <Text
                      color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                      fontSize={14}
                      fontWeight={"400"}
                      fontFamily={"mainFont"}
                  >
                    {listings_count} {pluralize('offer', listings_count)} from:
                  </Text>
                  <Text
                      color={getCurrentColor(colorMode, "blueC.100", "whiteC.100")}
                      fontSize={18}
                      fontWeight={"600"}
                      fontFamily={"mainFont"}
                  >
                    ${(+lowest_starts_from).toFixed(2)} CPR
                  </Text>
                </Flex>
                <Link
                    to={productGroupLink(product_group_id, cookies.zip)}
                    as={RouterLink}
                    px={!isProductsCategoriesMode ? "18px" : 0}
                    py={"10px"}
                    bg={"blueC.100"}
                    _hover={{ bg: "blueC.200" }}
                    color={"whiteC.100"}
                    borderRadius={"5px"}
                    w={!isProductsCategoriesMode ? "125px" : "250px"}
                    h={"40px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mt={{ base: "0px", xl: "auto" }}
                >
                  {!isProductsCategoriesMode ? 'View' : 'View All Offers & Product Info'}
                </Link>
              </Flex>
          }
          {(!isProductsCategoriesMode || slider) && (
              <ToolTipIssue id={listing_id} isVariant={is_variant}>
                <Box
                    cursor={"pointer"}
                    border={"1px solid"}
                    display={"flex"}
                    bottom={"-10px"}
                    right={"-10px"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.1600")}
                    position={"absolute"}
                    borderRadius={"50%"}
                    w={"20px"}
                    h={"20px"}
                >
                  <FlagIcon
                      w={"10px"}
                      h={"10px"}
                      color={getCurrentColor(colorMode, "grayC.2700", "grayC.1600")}
                  />
                </Box>
              </ToolTipIssue>
          )}
        </Box>
        {!slider && isProductsCategoriesMode && areListingsOpened &&
            <List>
              {listings.map((item) => (
                  <ListItem
                      border={"1px solid"}
                      borderColor={getCurrentColor(colorMode, "grayC.500", "grayC.2400")}
                      borderRadius={"5px"}
                      pt={{ base: "17px", md: recommend_flag === "1" ? 8 : 4 }}
                      pb={{ base: 3, md: "16px" }}
                      px={5}
                      display={{ base: "none", md: "flex" }}
                      justifyContent={"space-between"}
                      position={"relative"}
                      w={"100%"}
                      boxShadow={
                        "0px 4px 14px 0px rgba(183, 183, 183, 0.02), 0px 20px 40px 0px rgba(183, 183, 183, 0.06)"
                      }
                      width={'100%'}
                      bg={getCurrentColor(colorMode, "grayC.600", "grayC.2000")}
                  >
                    <Flex
                        direction={"column"}
                        maxW={"150px"}
                        w={"100%"}
                        gap={"3px"}
                    >
                      {item.vendor_name.length > 15 ? (
                          <Tooltip
                              cursor={"pointer"}
                              hasArrow
                              label={vendor_name}
                              bg="gray.300"
                              color="black"
                              fontSize={"xs"}
                          >
                            <Text
                                fontSize={"xs"}
                                mb={"5px"}
                                fontFamily={"mainFont"}
                            >
                              {item.vendor_name.length > 15
                                  ? `Sold by: ${item.vendor_name.slice(0, 14)}...`
                                  : `Sold by: ${item.vendor_name}`}
                            </Text>
                          </Tooltip>
                      ) : (
                          <Text fontSize={"xs"} mb={"5px"} fontFamily={"mainFont"}>
                            Sold by: {item.vendor_name}
                          </Text>
                      )}
                      <Rating rating={item.vendor_rating} reviews={item.vendor_rating_count}/>
                      {item.gb_flag === 1 && (
                          <Flex mt={'4px'} gap={"5px"}>
                            <Image
                                w={"34px"}
                                loading="lazy"
                                src="https://cdn.bulletscout.com/image/main-page/vendors/12.webp"
                                alt="gun broker"
                            />
                            <Text
                                fontSize={"xs"}
                                color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                                fontFamily={"mainFont"}
                            >
                              via GunBroker
                            </Text>
                          </Flex>
                      )}
                    </Flex>
                    <Flex
                        direction={"column"}
                        minW={"50px"}
                    >
                      <Text
                          fontSize={"xs"}
                          mb={"5px"}
                          fontFamily={"mainFont"}
                      >
                        Rounds
                      </Text>
                      <Flex gap={1}>
                        <Text
                            fontSize={"s"}
                            mb={"5px"}
                            fontFamily={"mainFont"}
                            as={"b"}
                        >
                          {item.rdsperbox}
                        </Text>
                        {item.is_variant === 1 && <TextTooltip text={`This listing reflects the purchase of multiple units, please make sure to add ${item.per_unit} ${pluralize('unit', per_unit)} to cart.`}>
                          <InfoIcon pl={'3px'}/>
                        </TextTooltip>}
                      </Flex>
                    </Flex>
                    <Flex
                        direction={"column"}
                        minW={"50px"}
                    >
                      <Text
                          fontSize={"xs"}
                          mb={"5px"}
                          fontFamily={"mainFont"}
                      >
                        Item Cost
                      </Text>
                      <Text
                          fontSize={"s"}
                          mb={"5px"}
                          fontFamily={"mainFont"}
                          as={"b"}
                      >
                        {item.cpr} CPR
                      </Text>
                      <Text
                          fontSize={"xs"}
                          mb={"5px"}
                          fontFamily={"mainFont"}
                      >
                        ${(+item.price).toFixed(2)}
                      </Text>
                    </Flex>
                    <Flex
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        minW={"300px"}
                    >
                      <Flex
                          direction={"column"}
                          gap={"8px"}
                      >
                        <Text
                            color={getCurrentColor(colorMode, "grayC.200", "grayC.2600")}
                            fontSize={"xs"}
                            fontWeight={"500"}
                            fontFamily={"mainFont"}
                        >
                          Total Cost
                        </Text>
                        <Heading
                            color={getCurrentColor(colorMode, "blueC.200", "whiteC.100")}
                            textDecor={"underline"}
                            fontSize={{ base: "xs", lg: "sm", xl: "md" }}
                            fontWeight={"700"}
                            fontFamily={"mainFont"}
                            display={"flex"}
                            gap={"6px"}
                            alignItems={"center"}
                            cursor={"pointer"}
                        >
                          <ToolTipTotal
                              price={item.price}
                              totalCost={item.landed_price}
                              ship_cost={item.ship_cost}
                              status={item.ship_flag}
                              taxes={item.taxes}
                          >
                            $
                            {(+item.landed_cpr).toFixed(2)}{" "}
                            CPR
                            <Box as={"span"} cursor={"pointer"}>
                              {cautionStatus(item.ship_flag).icon}
                            </Box>
                          </ToolTipTotal>
                        </Heading>
                        <Text
                            color={getCurrentColor(colorMode, "blackC.100", "whiteC.100")}
                            fontSize={"13px"}
                            fontWeight={"400"}
                            fontFamily={"mainFont"}
                        >
                          ${(+item.landed_price).toFixed(2)}
                        </Text>
                      </Flex>
                      <Link
                          href={`${item.affiliate_url}`}
                          target="_blank"
                          px={'18px'}
                          py={"10px"}
                          bg={"blueC.100"}
                          _hover={{ bg: "blueC.200" }}
                          color={"whiteC.100"}
                          borderRadius={"5px"}
                          w={'125px'}
                          h={"40px"}
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                      >
                        View
                      </Link>
                    </Flex>
                  </ListItem>
              ))}
            </List>
        }
        {!slider && isProductsCategoriesMode &&
            <Flex
                display={{ base: "none", md: "flex" }}
                color={getCurrentColor(colorMode, "blackC.300", "whiteC.100")}
                bg={getCurrentColor(colorMode, "grayC.400", "grayC.3000")}
                justifyContent={"center"}
                alignItems={"center"}
                m={0}
                width="100%"
                fontSize={16}
                pt={'4px'}
                pb={'4px'}
                onClick={handleOpenListingsToggle}
                cursor={"pointer"}
            >
              <Text
                  fontWeight={600}
              >
                {areListingsOpened ? 'Collapse Top Offers' : 'Preview Top Offers'}
                {areListingsOpened ?
                    <ChevronUpIcon
                        color={getCurrentColor(colorMode, "blackC.300", "grayC.1600")}
                        w={"24px"}
                        h={"24px"}
                        cursor={"pointer"}
                        pb={'2px'}
                    />
                    :
                    <ChevronDownIcon
                        color={getCurrentColor(colorMode, "blackC.300", "grayC.1600")}
                        w={"24px"}
                        h={"24px"}
                        cursor={"pointer"}
                        pb={'2px'}
                    />
                }
              </Text>
            </Flex>
        }
      </ListItem>
  );
};

export default ProductItemDesktop;
