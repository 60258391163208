import { Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

import ProductsList from "../components/ProductList/ProductsList";

import FiltersDesktop from "../components/Filters/FiltersDesktop";
import FiltersMobile from "../components/Filters/mobile/FiltersMobile";

import { memo } from "react";
import { Helmet } from "react-helmet";
import InfoOnProductsPage from "../components/InfoOnProductsPage/InfoOnProductsPage";
import ItemInfoDesktop from "../components/ItemInfo/ItemInfoDesktop";
import RefreshPopup from "../components/RefreshPopup/RefreshPopup";
import {useAtom} from "jotai/index";
import {categoriesPageModeAtom} from "../atom/listings";
import ProductGroupList from "../components/ProductList/ProductGroupList";
import ListingsList from "../components/ProductList/ListingsList";

const ProductsPage = ({ slider = false }) => {
  const location = useLocation();

  const [categoriesPageMode] = useAtom(categoriesPageModeAtom);

  const isListingsMode = categoriesPageMode === "listings";

  const currentCaliber = decodeURIComponent(location.pathname)
    .split("/")
    .slice(2)
    .join("/");

  return (
    <>
      <RefreshPopup />
      <Flex
        maxW={"1240px"}
        w={"100%"}
        mx={"auto"}
        pt={{ base: "22px", lg: "45px" }}
        flexDirection={"column"}
        mb={{ base: "72px", md: "64px" }}
        px={{ base: "22px", lg: "10px" }}
      >
        <Helmet>
          <title>{currentCaliber}</title>
          <meta
            name="description"
            content="Bullet Scout, your premier destination for finding the right ammunition for your firearm needs. With our comprehensive search engine, you can explore a wide array of ammunition options, ensuring you always hit your mark."
          />
        </Helmet>
        <InfoOnProductsPage slider={slider} />
        <Flex justifyContent={"space-between"} w={"100%"} gap={"20px"}>
          {(
            <>{slider ? <ItemInfoDesktop /> : <FiltersDesktop />}</>
          )}

          {slider ?
            <ProductGroupList slider={slider}/>
              : isListingsMode ?
                  <ListingsList/>
                  : <ProductsList/>
          }
        </Flex>
        {!slider && <FiltersMobile />}
      </Flex>

      {/*{slider && <SimilarProducts />}*/}
    </>
  );
};

export default memo(ProductsPage);